/*** 

====================================================================
Main Header
====================================================================

***/

.navbar{
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-toggler{
  border: solid 1px #000000;
}

.unScrolled{
  background-color: #f8f9fa !important;
}

.scrolled{
  background-color: #ffffff !important;
  box-shadow: 0 2px 8px 0 rgb(99 99 99 / 20%);
  position: fixed !important;
}

.main-header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 99;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  
  .header-span {
    position: relative;
    display: block;
    width: 100%;
    height: 100px;
    z-index: 1;
  
  }
  
  .main-header.fixed-header {
    position: fixed;
    background: #ffffff;
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,.10);
    box-shadow: 0 0 10px rgba(0,0,0,.10);
  }
  
  .main-header.fixed-header .logo{
    padding: 15px 0;
  }
  
  .main-header .main-box {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 60px;
    max-width: 1920px;
    margin: 0 auto;
  }
  
  .main-header .container-fluid{
    max-width: 1920px !important;
  }
  .main-header .nav-outer {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .main-header .logo-box {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .main-header .logo {
    position: relative;
    z-index: 9;
    padding: 10px 40px 10px 40px;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  
  @media screen and (max-width: 768px) {
    .main-header .logo {
      padding: 10px 20px 10px 0px;
    }
  }
  
  .main-header .menu-box {
    position: static;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .main-menu {
    position: static;
  }
  
  .main-menu .navbar-header {
    display: none;
  }
  
  .main-menu .navbar-collapse {
    padding: 0px;
  }
  
  .main-menu .navigation {
    position: static;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .main-menu .navigation > li {
    position: relative;
    margin-right: 40px;
    padding: 20px 0;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  
  .main-menu .navigation > li > span,
  .main-menu .navigation > li > a {
    position: relative;
    display: block;
    text-align: center;
    opacity: 1;
    font-size: 15px;
    line-height: 30px;
    font-weight: 400;
    color: #202124;
    cursor: pointer;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  
  .main-menu .navigation > li.current > a,
  .main-menu .navigation > li.current > span {
    color: #1967D2;
  }
  
  .main-menu .navigation > li.dropdown > span {
    padding-right: 18px;
  }
  
  .main-menu .navigation > li.dropdown > span:after{
    position: absolute;
    right: 0px;
    top: 50%;
    margin-top: -10px;
    font-family: "Font Awesome 5 Free";
    content: "\f107";
    display: block;
    line-height: 20px;
    font-size: 14px;
    font-weight: 900;
    z-index: 5;
    color: #202124;
  }
  
  .main-menu .navigation > li > a > span {
    position: relative;
  }
  
  .main-menu .navigation > li > ul {
    position: absolute;
    top: 100%;
    left: 0;
    padding: 15px 0;
    opacity: 0;
    z-index: 1;
    min-width: 230px;
    border-top: 2px solid #1967D2;
    border-radius: 0px 0px 8px 8px;
    background-color: #fff;
    -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    -ms-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
  
  .main-menu .navigation > li > ul:before {
    position: absolute;
    left: 20px;
    top: -7px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 5px solid #1967D2;
    content: "";
  }
  
  .main-menu .navigation > li > ul.from-right {
    left: auto;
    right: 0;
  }
  
  .main-menu .navigation > li > ul .from-right {
    left: auto;
    right: 100%;
    margin-right: 2px;
  }
  
  .main-menu .navigation > li > ul > li {
    position: relative;
    display: block;
    z-index: 1;
  }
  
  .main-menu .navigation > li > ul > li:last-child {
    border-bottom: none;
  }
  
  .main-menu .navigation > li > ul > li > span,
  .main-menu .navigation > li > ul > li > a {
    position: relative;
    display: block;
    padding: 10px 30px;
    z-index: 1;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #696969;
    white-space: nowrap;
    text-transform: capitalize;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  
  .main-menu .navigation > li > ul > li > span:hover,
  .main-menu .navigation > li > ul > li > a:hover {
    color: #1967D2;
  }
  
  .main-menu .navigation > li.current.dropdown > span:after,
  .main-menu .navigation > li > ul > li > ul > li.current > a,
  .main-menu .navigation > li > ul > li.current > a {
    color: #1967D2;
  }
  
  .main-menu .navigation > li > ul > li > ul {
    position: absolute;
    top: 100%;
    left: 100%;
    padding: 10px 0;
    min-width: 220px;
    margin-top: -57px;
    opacity: 0;
    z-index: 1;
    border-top: 2px solid #1967D2;
    background-color: #fff;
    -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    -ms-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
  }
  
  .main-menu .navigation > li > ul > li > ul > li {
    position: relative;
    width: 100%;
  }
  
  .main-menu .navigation > li > ul > li > ul > li > a {
    position: relative;
    display: block;
    padding: 10px 30px;
    z-index: 1;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #363636;
    white-space: nowrap;
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  
  .main-menu .navigation > li > ul > li > ul > li > a:hover,
  .main-menu .navigation > li > ul > li > ul > li > span:hover {
    color: #1967D2;
  }
  
  .main-menu .navigation > li > ul > li.dropdown > span:before {
    position: absolute;
    right: 30px;
    top: 50%;
    margin-top: -10px;
    font-family: "Font Awesome 5 Free";
    content: "\f105";
    display: block;
    line-height: 20px;
    font-size: 15px;
    font-weight: 900;
    z-index: 5;
  }
  
  .main-menu .navigation > li.dropdown:hover > ul {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
  
  .main-menu .navigation li > ul > li.dropdown:hover > ul {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(0px);
    -ms-transform: translate(0px);
    transform: translate(0px);
  }
  
  .main-menu .navigation li.dropdown .dropdown-btn {
    position: absolute;
    right: 10px;
    top: 8px;
    width: 34px;
    height: 30px;
    border: 1px solid #ffffff;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    cursor: pointer;
    z-index: 5;
    display: none;
  }
  
  .main-header .outer-box {
    position: absolute;
    right: 0 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 38px;
  }
  
  .main-header .outer-box .btn-box .theme-btn{
    min-width: 100px;
    padding: 9px 20px 8px 20px;
    font-size: 14px;
    margin: 0 10px;
  }
  
  .main-header .outer-box .btn-box{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .main-header .menu-btn{
    position: relative;
    margin-left: 30px;
  }
  
  .main-header .menu-btn .icon{
    font-size: 24px;
    color: #696969;
  }
  
  .main-header .menu-btn .count{
    position: absolute;
    right: -8px;
    top: -4px;
    height: 16px;
    width: 16px;
    background: #1967D2;
    border-radius: 50%;
    color: #ffffff;
    line-height: 16px;
    font-size: 12px;
  }
  
  .main-header.alternate {
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,.10);
    box-shadow: 0 0 10px rgba(0,0,0,.10);
  }
  
  .main-header.alternate .main-box{
    padding: 0;
  }
  
  .main-header.alternate .main-box .logo{
    margin-right: 80px;
  }
  
  .main-header.alternate2 .outer-box .btn-box a{
    border-radius: 5px !important;
  }
  
  .main-header.header-style-two.alternate .main-box .logo{
    margin-right: 30px;
  }
  
  .main-header.header-style-two.fixed-header {
    background-color: #22218c;
  }
  
  .main-header.header-shaddow{
    -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    border-bottom: 1px solid #ECEDF2;
  }
  
  .main-header.alternate3{
    -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    background-color: #ffffff;
  }
  
  /* Dashboard Option */
  
  #toggle-user-sidebar{
    display: none;
  }
  
  .dashboard-option{
    position: relative;
    margin-left: 30px;
  }
  
  .dashboard-option .dropdown-menu{
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    top: 100% !important;
    left: auto !important;
    right: 0 !important;
    min-width: 300px;
    margin-top: 8px;
    background: #FFFFFF;
    border: 1px solid #ECEDF2;
    -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    border-radius: 8px;
    padding: 30px 30px 17px;
  }
  
  .dashboard-option .dropdown-menu:before{
    position: absolute;
    right: 30px;
    top: -8px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #ECEDF2;
    content: "";
  }
  
  .dashboard-option .dropdown-toggle{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .dashboard-option .dropdown-toggle::after{
    margin-left: 12px;
    content: "\f107";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    border: 0;
  }
  
  .dashboard-option .thumb{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .dashboard-option .name{
    position: relative;
    display: block;
    color: #202124;
    font-size: 15px;
    line-height: 28px;
    margin-left: 20px;
  }
  
  .dashboard-option .dropdown-menu li a{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 30px;
    line-height: 30px;
    font-weight: 400;
    font-size: 15px;
    color: #696969;
    text-align: left;
    text-transform: capitalize;
    border-radius: 8px;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
  }
  
  .dashboard-option .dropdown-menu li a i{
    position: relative;
    margin-right: 15px;
    font-size: 22px;
    color: #696969;
    text-align: center;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
  }
  
  .dashboard-option .dropdown-menu li.active a,
  .dashboard-option .dropdown-menu li:hover a{
    color: #1967D2;
    background: rgba(25, 103, 210, .1);
  }
  
  .dashboard-option .dropdown-menu li.active a i,
  .dashboard-option .dropdown-menu li:hover a i{
    color: #1967D2;
  }
  
  /*** 
  
  ====================================================================
  Header Style Two
  ====================================================================
  
  ***/
  
  .header-style-two .main-box{
    padding: 0;
  }
  
  .header-style-two .nav-outer{
    width: 100%;
  }
  
  .header-style-two .main-menu{
    margin-left: auto;
    margin-right: 15px;
  }
  
  .header-style-two .logo{
    margin-right: 0;
  }
  
  .header-style-two .main-menu .navigation > li{
    margin-left: 45px;
    margin-right: 0;
  }
  
  .header-style-two .main-menu .navigation > li.dropdown > span:after,
  .header-style-two .main-menu .navigation > li > span,
  .header-style-two .main-menu .navigation > li > a {
    color: #ffffff;
  }
  
  .header-style-two .upload-cv,
  .header-style-two .main-menu .navigation > li > ul > li > a:hover,
  .header-style-two .main-menu .navigation > li > ul > li > span:hover, 
  .header-style-two .main-menu .navigation > li > ul > li > a:hover,
  .header-style-two .main-menu .navigation > li > ul > li > ul > li > a:hover,
  .header-style-two .main-menu .navigation > li > ul > li > ul > li > span:hover,
  .header-style-two .main-menu .navigation > li .mega-menu-bar .column > ul > li > a:hover,
  .header-style-two .main-menu .navigation > li.current.dropdown > span:after, 
  .header-style-two .main-menu .navigation > li > ul > li > ul > li.current > a, 
  .header-style-two .main-menu .navigation > li > ul > li.current > a,
  .header-style-two .main-menu .navigation > li.current.dropdown > span:after, 
  .header-style-two .main-menu .navigation > li.current > a,
  .header-style-two .main-menu .navigation > li.current > span {
    color: #F9AB00;
  }
  
  .header-style-two .main-menu .navigation > li > ul > li > ul,
  .header-style-two .main-menu .navigation > li > .mega-menu,
  .header-style-two .main-menu .navigation > li > ul{
    border-top-color: #F9AB00;
  }
  
  .header-style-two .main-menu .navigation > li > .mega-menu:before,
  .header-style-two .main-menu .navigation > li > ul:before{
    border-bottom-color: #F9AB00;
  }
  
  .header-style-two .theme-btn{
    border-radius: 50px;
  }
  
  /*** 
  
  ====================================================================
  Header Style Three
  ====================================================================
  
  ***/
  
  .header-style-three .main-box{
    padding: 0 45px;
  }
  
  .header-style-three .upload-cv,
  .header-style-three .main-menu .navigation > li > ul > li > a:hover,
  .header-style-three .main-menu .navigation > li > ul > li > span:hover, 
  .header-style-three .main-menu .navigation > li > ul > li > a:hover,
  .header-style-three .main-menu .navigation > li > ul > li > ul > li > a:hover,
  .header-style-three .main-menu .navigation > li > ul > li > ul > li > span:hover,
  .header-style-three .main-menu .navigation > li .mega-menu-bar .column > ul > li > a:hover,
  .header-style-three .main-menu .navigation > li.current.dropdown > span:after, 
  .header-style-three .main-menu .navigation > li > ul > li > ul > li.current > a, 
  .header-style-three .main-menu .navigation > li > ul > li.current > a,
  .header-style-three .main-menu .navigation > li.current.dropdown > span:after, 
  .header-style-three .main-menu .navigation > li.current > a,
  .header-style-three .main-menu .navigation > li.current > span {
    color: #34A853;
  }
  
  .header-style-three .main-menu .navigation > li > ul > li > ul,
  .header-style-three .main-menu .navigation > li > .mega-menu,
  .header-style-three .main-menu .navigation > li > ul{
    border-top-color: #34A853;
  }
  
  .header-style-three .main-menu .navigation > li > .mega-menu:before,
  .header-style-three .main-menu .navigation > li > ul:before{
    border-bottom-color: #34A853;
  }
  
  /*** 
  
  ====================================================================
  Header Style Four
  ====================================================================
  
  ***/
  
  .header-style-four .nav-outer{
    width: 100%;
  }
  
  .header-style-four .main-menu{
    margin-left: auto;
    margin-right: 15px;
  }
  
  .header-style-four .main-menu .navigation > li{
    margin-left: 45px;
    margin-right: 0;
  }
  
  .header-style-four .main-menu .navigation > li.dropdown > span:after,
  .header-style-four .main-menu .navigation > li > span,
  .header-style-four .main-menu .navigation > li > a {
    color: #ffffff;
  }
  
  .header-style-four .upload-cv,
  .header-style-four .main-menu .navigation > li > ul > li > a:hover,
  .header-style-four .main-menu .navigation > li > ul > li > span:hover, 
  .header-style-four .main-menu .navigation > li > ul > li > a:hover,
  .header-style-four .main-menu .navigation > li > ul > li > ul > li > a:hover,
  .header-style-four .main-menu .navigation > li > ul > li > ul > li > span:hover,
  .header-style-four .main-menu .navigation > li .mega-menu-bar .column > ul > li > a:hover,
  .header-style-four .main-menu .navigation > li > ul > li > ul > li.current > a, 
  .header-style-four .main-menu .navigation > li > ul > li.current > a{
    color: #D93025;
  }
  
  .header-style-four .main-menu .navigation > li > ul > li > ul,
  .header-style-four .main-menu .navigation > li > .mega-menu,
  .header-style-four .main-menu .navigation > li > ul{
    border-top-color: #D93025;
  }
  
  .header-style-four .main-menu .navigation > li > .mega-menu:before,
  .header-style-four .main-menu .navigation > li > ul:before{
    border-bottom-color: #D93025;
  }
  
  .header-style-four .btn-style-five{
    color: #202124;
  }
  
  .header-style-four .btn-style-five:hover{
    color: #ffffff;
  }
  
  .header-style-four.fixed-header{
    background: #262C3F;
  }
  
  /*** 
  
  ====================================================================
  Header Style Five
  ====================================================================
  
  ***/
  
  
  .header-style-five .logo-box{
    position: absolute;
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  
  .header-style-five .logo{
    padding: 25px 0;  
  }
  
  .header-style-five .outer-box{
    padding: 25px 0;
  }
  
  .header-style-five.fixed-header .outer-box{
    padding: 15px 0;
  }
  
  .header-style-five.fixed-header .logo{
    padding: 10px 0;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  
  /*** 
  
  ====================================================================
  Sticky Header
  ====================================================================
  
  ***/
  
  .sticky-header {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    left: 0px;
    top: 0px;
    width: 100%;
    padding: 0px 0px;
    z-index: 99;
    background: rgba(255, 255, 255, 0.952941);
    -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.10);
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  
  .sticky-header.fixed-header{
    opacity: 1;
    z-index: 99;
    visibility: visible;
  }
  
  .sticky-header .main-box{
    padding: 0;
    margin: 0;
  }
  
  .sticky-header .logo,
  .sticky-header .outer-box,
  .sticky-header .main-box:before{
    display: none;
  }
  
  .sticky-header .sticky-logo{
    display: block;
  }
  
  .sticky-header .main-menu .navigation > li.dropdown > span:after,
  .sticky-header .main-menu .navigation > li.dropdown > span,
  .sticky-header .main-menu .navigation > li.dropdown > a:after,
  .sticky-header .main-menu .navigation > li > a {
    color: #363636;
  }
  
  .sticky-header .main-menu .navigation > li.current.dropdown > span:after,
  .sticky-header .main-menu .navigation > li.current > span,
  .sticky-header .main-menu .navigation > li.current.dropdown > a:after,
  .sticky-header .main-menu .navigation > li.current > a {
    color: #1967D2;
  }
  
  /*** 
  
  ====================================================================
  Mobile Header
  ====================================================================
  
  ***/
  
  .mobile-header {
    position: relative;
    padding: 20px 15px;
    display: none;
    z-index: 99;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  }
  
  .mobile-header:after {
    display: table;
    clear: both;
    content: "";
  }
  
  .mobile-header .logo {
    position: relative;
    float: left;
    margin: 0 !important;
    padding: 0 !important;
    left: 0;
  }
  
  .mobile-header .logo img {
    max-height: 50px;
  }
  
  .mobile-header .nav-outer {
    position: relative;
    float: right;
  }
  
  .mobile-header .outer-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .mobile-header .search-box .search-btn {
    font-size: 20px;
    color: #ffffff;
  }
  
  .mobile-header .search-box {
    margin: 0;
  }
  
  .mobile-header .cart-btn > a {
    font-size: 20px;
  }
  
  .mobile-header .cart-btn > a,
  .mobile-header .login-box {
    margin-left: 20px;
  }
  
  .nav-outer .mobile-nav-toggler {
    position: relative;
    float: right;
    font-size: 30px;
    line-height: 30px;
    cursor: pointer;
    margin-left: 20px;
    width: 30px;
    color: #202124;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1);
    display: none;
  }
  
  .mobile-header .login-box .icon-user{
    position: relative;
    height: 18px;
    width: 18px;
    display: block;
    /* background-image: url(../images/icons/user.svg); */
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .mobile-header .login-box a{
    color: #202124;
    font-size: 20px;
  
  }
  
  .mm-panels {
    --mm-color-background: #202124;
    --mm-color-text-dimmed: #979797;
    --mm-color-border: rgba(255, 255, 255, 0.1);
    --mm-listitem-size: 50px;
  }
  
  .mm-menu a, 
  .mm-menu a:active, 
  .mm-menu a:hover, 
  .mm-menu a:link, 
  .mm-menu a:visited{
    color: #979797;
  }
  
  .mm-menu li{
    padding: 0 20px;
  }
  
  .mm-menu .mm-listitem:after{
    display: none;
  }
  
  .mm-menu li.current > a{
    color: #ffffff;
    background: rgba(255,255,255,.20);
    border-radius: 5px;
    line-height: 30px;
  }
  
  .mm-btn:after,
  .mm-btn:before {
    border-color: #ffffff;
    border-width: 1.5px;
    height: 6px;
    width: 6px;
  }
  
  .mm-panels .mm-counter {
    position: absolute;
    right: 45px;
    top: 50%;
    text-indent: 0;
    display: block;
    margin-top: -10px;
    background-color: rgba(255, 167, 55, 0.8);
    height: 20px;
    width: 20px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    line-height: 22px;
    font-size: 12px;
    font-weight: 600;
    padding: 0;
  }
  
  #toggle-user-sidebar{
    margin-left: 20px;
  }
  
  #toggle-user-sidebar .thumb{
    display: block;
    height: 30px;
    width: 30px;
    border: 2px solid rgba(255,255,255,.15);
    border-radius: 50%;
  }
  
  .mm-menu_fullscreen.mm-menu_position-bottom{
    top: 80px;
  }
  
  .mm-navbar_sticky{
    padding: 7px 0;
  }
  
  .mm-listview{
    padding-top: 20px;
  }
  
  .mm-menu li{
    margin-bottom: 10px;
  }
  
  .mobile-nav-toggler .flaticon-menu-1:before{
    -webkit-transition: all 100ms ease;
    -o-transition: all 100ms ease;
    transition: all 100ms ease;
  }
  
  .mm-wrapper_opened .mobile-nav-toggler .flaticon-menu-1:before{
    content: "\f175";
    font-size: 18px;
    line-height: 30px;
    display: block;
  }
  
  .mm-add-listing{
    margin-top: 87px;
    border: transparent;
    display: none;
  }
  
  .mm-add-listing .theme-btn{
    border-radius: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    color: #ffffff !important;
    height: 60px;
    border-radius: 10px;
  }
  
  .mm-add-listing .mm-listitem__text{
    padding: 0;
  }
  
  .mm-add-listing .contact-info{
    position: relative;
    padding: 18px 0;
    display: block;
  }
  
  .mm-add-listing .phone-num{
    position: relative;
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 13px;
    color: #7E7E7E;
  }
  
  .mm-add-listing .phone-num span{
    display: block;
  }
  
  .mm-add-listing .phone-num a{
    color: #ffffff;
  }
  
  .mm-add-listing .address{
    position: relative;
    display: block;
    font-size: 14px;
    line-height: 30px;
    color: #7E7E7E;
  }
  
  .mm-add-listing .email{
    font-size: 14px;
    line-height: 30px;
    color: #7E7E7E !important;
  }
  
  .mm-add-listing .social-links{
    position: relative;
    display: block;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .mm-add-listing .social-links a{
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #7E7E7E;
    margin-right: 37px;
  }
  
  .mm-panel:after{
    height: 10px;
  }
  
  /************************ Mega Menu ***************************/
  
  .main-menu .navigation > li.has-mega-menu{
    position:static;
  }
  
  .main-menu .navigation > li > .mega-menu .image-box{
    position: absolute;
    right: -230px;
    top: -15px;
    padding: 20px;
  }
  
  .main-menu .navigation > li > .mega-menu{
    position:absolute;
    left:auto;
    right: auto;
    margin: auto;
    width: 880px;
    background:#ffffff;
    padding: 30px 30px;
    min-height: 225px;
    top: 100%;
    z-index:100;
    opacity: 0;
    margin-top: -15px;
    -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    border-radius: 0px 0px 8px 8px;
    border-top: 2px solid #1967D2;
    -webkit-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    transition:all 300ms ease;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
  }
  
  .main-menu .navigation > li:hover > .mega-menu{
    opacity: 1;
    visibility: visible;
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
  
  .main-menu .navigation > li > .mega-menu:before{
    position: absolute;
    left: 20px;
    top: -7px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 5px solid #1967D2;
    content: "";
  } 
  
  
  .main-menu .navigation > li > .mega-menu .mega-menu-bar{
    position: relative;
    padding-top: 40px;
  }
  
  .main-menu .navigation > li .mega-menu-bar .column{
    position: relative;
  }
  
  .main-menu .navigation > li .mega-menu-bar h3{
    position:absolute;
    top: -40px;
    left: 15px;
    font-size:16px;
    line-height: 25px;
    color:#202124;
    font-weight:500;
    margin-bottom: 15px;
  }
  
  .main-menu .navigation > li .mega-menu-bar > ul{
    position:relative;
  
  }
  
  .main-menu .navigation > li .mega-menu-bar .column > ul > li{
    position:relative;
    width:100%;
    text-transform:capitalize;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
  }
  
  .main-menu .navigation > li .mega-menu-bar .column{
    position:relative;
    margin-bottom:10px;
  }
  
  .main-menu .navigation > li .mega-menu-bar .column > ul > li:last-child{
    margin-bottom: 0;
  }
  
  .main-menu .navigation > li .mega-menu-bar .column > ul > li > a{
    position:relative;
    display:block;
    line-height:20px;
    font-weight:400;
    font-size:15px;
    color:#202124;
    padding: 10px 0;
    text-align:left;
    text-transform:capitalize;
    -webkit-transition:all 500ms ease;
    -o-transition:all 500ms ease;
    transition:all 500ms ease;
  }
  
  .main-menu .navigation > li .mega-menu-bar .column > ul > li > a:hover{
    color: #1967D2;
  }
  
  /*** 
  ======================
  Buttons Styles
  ======================
  ***/
  
  .theme-btn{
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    overflow: hidden;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1;
  }
  
  /* Btn Style One */
  .btn-style-one {
    position: relative;
    text-align: center;
    white-space: nowrap;
    color: #ffffff;
    background-color: #1967D2;
    font-size: 15px;
    line-height: 20px;
    border-radius: 8px;
    font-weight: 400;
    padding: 18px 35px 15px 35px;
  }
  
  .btn-style-one:hover{
    color: #ffffff;
    background-color: #0146A6;
  }
  
  /* Btn Style Two */
  .btn-style-two {
    position: relative;
    text-align: center;
    white-space: nowrap;
    color: #202124;
    background-color: #F9AB00;
    font-size: 15px;
    line-height: 20px;
    border-radius: 8px;
    font-weight: 400;
    padding: 18px 35px 15px 35px;
  }
  
  .btn-style-two:hover{
    color: #202124;
    background-color: #E9A000;
  }
  
  /* Btn Style Three */
  .btn-style-three {
    position: relative;
    text-align: center;
    white-space: nowrap;
    color: #1967D2;
    background-color: #E2EAF8;
    font-size: 15px;
    line-height: 20px;
    border-radius: 8px;
    font-weight: 400;
    padding: 18px 35px 15px 35px;
  }
  
  .btn-style-three:hover{
    color: #FFFFFF;
    background-color: #1967D2;
  }
  
  /* Btn Style Four */
  .btn-style-four {
    position: relative;
    text-align: center;
    white-space: nowrap;
    color: #34A853;
    background-color: #E1F2E5;
    font-size: 16px;
    line-height: 20px;
    border-radius: 8px;
    font-weight: 400;
    padding: 15px 35px;
  }
  
  .btn-style-four:hover{
    color: #FFFFFF;
    background-color: #34A853;
  }
  
  /* Btn Style Five */
  .btn-style-five {
    position: relative;
    text-align: center;
    white-space: nowrap;
    color: #302EA7;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    line-height: 20px;
    border-radius: 8px;
    font-weight: 400;
    padding: 14px 35px;
  }
  
  .btn-style-five:hover{
    color: #ffffff; 
    background-color: transparent;
  }
  
  /* Btn Style Six */
  .btn-style-six {
    position: relative;
    text-align: center;
    white-space: nowrap;
    color: #ffffff;
    background-color: transparent;
    border: 1px solid #ffffff;
    line-height: 20px;
    border-radius: 8px;
    font-weight: 400;
    padding: 14px 35px;
  }
  
  .btn-style-six:hover{
    color: #302EA7;
    background-color: #ffffff;
  }
  
  /* Btn Style Seven */
  .btn-style-seven {
    position: relative;
    text-align: center;
    white-space: nowrap;
    color: #ffffff;
    background-color: #34A853;
    line-height: 20px;
    border-radius: 8px;
    font-weight: 400;
    padding: 15px 35px;
  }
  
  .btn-style-seven:hover{
    color: #34A853;
    background-color: #E1F2E5;
  }
  
  /* Btn Style Seven */
  
  .btn-style-eight{
    position: relative;
    text-align: center;
    white-space: nowrap;
    color: #D93025;
    background-color: rgba(217,48,37,.07);
    line-height: 20px;
    border-radius: 8px;
    font-weight: 400;
    padding: 15px 35px;
  }
  
  .btn-style-eight:hover{
    color: #ffffff;
    background-color: rgba(217,48,37,1);
  }
  
  /* Btn Style Nine */
  .btn-style-nine {
    position: relative;
    text-align: center;
    white-space: nowrap;
    color: #1967D2;
    background-color: #E2EAF8;
    font-size: 15px;
    line-height: 20px;
    border-radius: 8px;
    font-weight: 400;
    padding: 13px 25px 13px 25px;
  }
  
  .btn-style-nine:hover{
    color: #FFFFFF;
    background-color: #1967D2;
  }
  
  /* Btn Style Ten */
  .btn-style-ten {
    position: relative;
    text-align: center;
    white-space: nowrap;
    color: #ffffff;
    background-color: #1967D2;
    font-size: 15px;
    line-height: 20px;
    border-radius: 8px;
    font-weight: 400;
    padding: 13px 18px 13px 18px;
  }
  
  .btn-style-ten:hover{
    color: #ffffff;
    background-color: #0146A6;
  }
  
  .theme-btn.large {
    padding: 20px 50px;
    font-size: 16px;
    line-height: 20px;
  }
  
  .theme-btn.small {
    padding: 5px 18px;
    font-size: 14px;
    min-width: 80px;
    font-weight: 400;
    line-height: 21px;
    border-radius: 5px;
  }