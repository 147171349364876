.navbar{
    padding-top: 0;
    padding-bottom: 0;
}
  
.navbar-toggler{
    border: solid 1px #000000;
}
  
.dashboardUnScrolled{
    background-color: #ffffff !important;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 20%);
}
  
.dashboardScrolled{
    background-color: #ffffff !important;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 20%);
    position: fixed !important;
}

.dashboard{
    position: relative;
    background-color: #F5F7FC;
    padding-left: 365px;
    min-height: 100vh;
}

.dashboard .main-header{
    background: #ffffff;
}

.dashboard .dashboard-outer{
    position: relative;
    padding: 60px 60px 0;
}

@media screen and (min-width: 1650px){
    .dashboard .dashboard-outer{
        margin: 0 40px 40px 40px;
    }
}

.dashboard .upper-title-box{
    position: relative;
    margin-bottom: 60px;
}

.dashboard .upper-title-box h3{
    font-weight: 500;
    font-size: 30px;
    line-height: 41px;
    color: #202124;
    margin-bottom: 10px;
}

.ui-item{
    position: relative;
    height: 130px;
    width: 100%;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: #FFFFFF;
    border: 1px solid #ECEDF2;
    -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 30px;
}

.ui-item .right{
    text-align: right;
}

.ui-item h4{
    font-size: 30px;
    color: #1967D2;
    font-weight: 500;
    line-height: 1em;
    margin-bottom: 3px;
}

.ui-item p{
    font-size: 15px;
    color: #202124;
    line-height: 26px;
}

.ui-item .icon{
    position: relative;
    display: block;
    height: 70px;
    width: 70px;
    line-height: 70px;
    font-size: 40px;
    color: rgba(25, 103, 210,1);
    text-align: center;
    background-color: rgba(25, 103, 210, .10);
    border-radius: 8px;
}

.ui-item.ui-red h4{color: rgba(217, 48, 37, 1); }
.ui-item.ui-red .icon{color: rgba(217, 48, 37, 1);background: rgba(217, 48, 37, .1) }

.ui-item.ui-yellow h4{color: rgba(249, 171, 0, 1) }
.ui-item.ui-yellow .icon{color: rgba(249, 171, 0, 1); background: rgba(249, 171, 0, .1)}

.ui-item.ui-green h4 {
    color: rgba(52, 168, 83, 1);
}
.ui-item.ui-green .icon {
    color: rgba(52, 168, 83, 1); 
    background: rgba(52, 168, 83, .1)
}



.page-wrapper.dashboard{
    padding-left: 300px;
}

.header-span {
    position: relative;
    display: block;
    width: 100%;
    height: 100px;
    z-index: 1;

}

.main-header.header-shaddow{
    -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    border-bottom: 1px solid #ECEDF2;
}

.dashboard-option .dropdown-toggle::after {
    margin-left: 12px;
    content: "\f107";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    border: 0;
}